<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";

//import VueEasyLightbox from "vue-easy-lightbox";
import Multiselect from "vue-multiselect";
//import { format } from "date-fns";
//import VueEasyLightbox from "vue-easy-lightbox";
/*import { MazPhoneNumberInput } from "maz-ui";
import moment from "moment"; */
//import image from "@/assets/images/users/user.png";

export default {
  page: {
    title: "Administration",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Administrateur",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Historistique",
          active: true,
        },
      ],
      order: [],
      ordersData: [],
      adminData: [],
      activityData: [],
      thenDay: "",
      showAll: false,
      users: [],
      rowCall: null,
      value: null,
      nbrAdmin: 0,
      dataUser: [],
      id: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "conducteur", sortable: true, label: "Conducteur" },
        { key: "place", sortable: true, label: "Places" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "total", sortable: true, label: "Montant" },
        { key: "action" },
      ],
      field: [
        { key: "userid", sortable: true, label: "Nom & Prénom" },
        { key: "total", sortable: true, label: "Montant viré" },
        { key: "date", sortable: true, label: "Effectué le" },
      ],
      admin: [
        { key: "firstname", sortable: true, label: "Nom & Prénom" },
        { key: "date", sortable: true, label: "Date de création" },
        { key: "etat", sortable: true, label: "Etat du compte" },
        { key: "action" },
      ],
      activity: [
        { key: "admin", sortable: true },
        { key: "details", sortable: true },
        { key: "date", sortable: true },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      toutDroits: false,
      trajetDroits: null,
      donsDroits: null,
      bonsDroits: null,
      messageDroits: null,
      reserveDroits: null,
      transactionsDroits: null,
      adminDroits: null,
      utilisateurDroits: null,
      alertesDroits: null,
      gainsDroits: null,
      virementDroits: null,
      accesTable: [],
      dateJournal: null,
    };
  },

  async mounted() {
    //Chargement des données
    this.init();
  },

  methods: {
    async init() {
      const data = await apiRequest("GET", "admin/users", undefined, false);
      if (data && data.data) {
        //console.log("data.data:", data.data);
        const formattedTable = data.data.map((user) => {
          return {
            id: user.id,
            name: user.lastname[0] + ". " + user.firstname,
          };
        });
        this.users = formattedTable;
        //console.log('this.users:', this.users)
      }

      const adminsTable = await apiRequest(
        "GET",
        "admin/admins",
        undefined,
        false
      );
      if (adminsTable && adminsTable.data) {
        console.log("Tableau admin", adminsTable.data);
        const formattedTable = adminsTable.data.map((user) => {
          return {
            id: user.id,
            firstname: user.firstname,
            date: new Date(user.dateCreation).toLocaleString("fr-FR"),
            etat: user.active ? "Actif" : "Suspendu",
          };
        });
        this.adminData = formattedTable;
        this.nbrAdmin = this.adminData.length;
      }
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      //console.log("this.$v:", this.$v);
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      //this.create();
    },
    async submitAdminAdd() {
      //console.log("Valeur", this.value.id);
      console.log("this.accesTable:", this.accesTable);
      const returnAddSucess = await apiRequest(
        "POST",
        "admin/set-admin",
        {
          idUser: this.value.id,
          active: true,
          acces: this.accesTable,
        },
        false
      );
      if (returnAddSucess && returnAddSucess.data) {
        console.log(returnAddSucess);
        this.init();
        (this.toutDroits = false),
          (this.trajetDroits = null),
          (this.donsDroits = null),
          (this.bonsDroits = null),
          (this.messageDroits = null),
          (this.this.reserveDroits = null),
          (this.transactionsDroits = null),
          (this.adminDroits = null),
          (this.utilisateurDroits = null),
          (this.alertesDroits = null),
          (this.gainsDroits = null),
          (this.virementDroits = null),
          (this.accesTable = []);
      }
    },
    desactivity(row) {
      this.showdisable = true;
      this.rowCall = row.id;
      console.log("row: ", row.id);
    },
    toutchange() {
      console.log("Touchange: ", this.toutDroits);

      if (this.toutDroits == false) {
        this.accesTable = [];
        this.accesTable.push(0);

        this.trajetDroits = true;
        this.messageDroits = true;
        this.reserveDroits = true;
        this.transactionsDroits = true;
        this.adminDroits = true;
        this.gainsDroits = true;
        this.virementDroits = true;
        this.utilisateurDroits = true;
        this.alertesDroits = true;
        this.bonsDroits = true;
        this.donsDroits = true;
      } else {
        this.accesTable = [];
        this.trajetDroits = false;
        this.messageDroits = false;
        this.reserveDroits = false;
        this.transactionsDroits = false;
        this.adminDroits = false;
        this.gainsDroits = false;
        this.virementDroits = false;
        this.utilisateurDroits = false;
        this.alertesDroits = false;
        this.bonsDroits = false;
        this.donsDroits = false;
      }
    },
    trajetchange() {
      if (this.trajetDroits == true) {
        this.toutDroits = false;
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 1) {
              this.accesTable.splice(i, 1);
              break;
            }
          }
        }
      } else {
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 1) {
              console.log("");
            } else {
              this.accesTable.push(1);
              break;
            }
          }
        } else {
          this.accesTable.push(1);
        }
      }
    },
    donschange() {
      if (this.donsDroits == true) {
        this.toutDroits = false;

        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 4) {
              this.accesTable.splice(i, 1);
              console.log("suprression");
              break;
            }
          }
          console.log("taille plus grand que 0");
        }
      } else {
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 4) {
              console.log("Danger");
            } else {
              this.accesTable.push(4);
              console.log("Danger");
              break;
            }
          }
        } else {
          this.accesTable.push(4);
        }
      }
    },
    bonschange() {
      if (this.bonsDroits == true) {
        this.toutDroits = false;
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 5) {
              this.accesTable.splice(i, 1);
              break;
            }
          }
        }
      } else {
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 5) {
              console.log("");
            } else {
              this.accesTable.push(5);
              break;
            }
          }
        } else {
          this.accesTable.push(5);
        }
      }
    },
    messagechange() {
      if (this.messageDroits == true) {
        this.toutDroits = false;
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 11) {
              this.accesTable.splice(i, 1);
              break;
            }
          }
        }
      } else {
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 11) {
              console.log("");
            } else {
              this.accesTable.push(11);
              break;
            }
          }
        } else {
          this.accesTable.push(11);
        }
      }
    },
    reservechange() {
      if (this.reserveDroits == true) {
        this.toutDroits = false;
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 2) {
              this.accesTable.splice(i, 1);
              break;
            }
          }
        }
      } else {
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 2) {
              console.log("");
            } else {
              this.accesTable.push(2);
              break;
            }
          }
        } else {
          this.accesTable.push(2);
        }
      }
    },
    transactionschange() {
      if (this.transactionDroits == true) {
        this.toutDroits = false;
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 7) {
              this.accesTable.splice(i, 1);
              break;
            }
          }
        }
      } else {
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 7) {
              console.log("");
            } else {
              this.accesTable.push(7);
              break;
            }
          }
        } else {
          this.accesTable.push(7);
        }
      }
    },
    adminchange() {
      if (this.adminDroits == true) {
        this.toutDroits = false;
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 10) {
              this.accesTable.splice(i, 1);
              break;
            }
          }
        }
      } else {
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 10) {
              console.log("");
            } else {
              this.accesTable.push(10);
              break;
            }
          }
        } else {
          this.accesTable.push(10);
        }
      }
    },
    utilisateurchange() {
      if (this.utilisateurDroits == true) {
        this.toutDroits = false;
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 3) {
              this.accesTable.splice(i, 1);
              break;
            }
          }
        }
      } else {
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 3) {
              console.log("");
            } else {
              this.accesTable.push(3);
              break;
            }
          }
        } else {
          this.accesTable.push(3);
        }
      }
    },
    alerteschange() {
      if (this.alertesDroits == true) {
        this.toutDroits = false;
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 6) {
              this.accesTable.splice(i, 1);
              break;
            }
          }
        }
      } else {
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 6) {
              console.log("");
            } else {
              this.accesTable.push(6);
              break;
            }
          }
        } else {
          this.accesTable.push(6);
        }
      }
    },
    gainschange() {
      if (this.gainsDroits == true) {
        this.toutDroits = false;
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 8) {
              this.accesTable.splice(i, 1);
              break;
            }
          }
        }
      } else {
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 8) {
              console.log("");
            } else {
              this.accesTable.push(8);
              break;
            }
          }
        } else {
          this.accesTable.push(8);
        }
      }
    },
    virementchange() {
      if (this.virementDroits == true) {
        this.toutDroits = false;
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 9) {
              this.accesTable.splice(i, 1);
              break;
            }
          }
        }
      } else {
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 9) {
              console.log("");
            } else {
              this.accesTable.push(9);
              break;
            }
          }
        } else {
          this.accesTable.push(9);
        }
      }
    },
    async desactiveUser() {
      this.showdisable = false;
      const returnAddSucess = await apiRequest(
        "POST",
        "admin/set-admin",
        {
          idUser: this.rowCall,
          active: false,
          acces: [],
        },
        false
      );
      if (returnAddSucess && returnAddSucess.data) {
        console.log(returnAddSucess);
        this.init();
      }
    },
    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    async search() {
      console.log("this.dateJournal:", this.dateJournal);
      if (this.dateJournal != null) {
        const data = await apiRequest(
          "GET",
          "admin/journal?all=" + false + "&date=" + this.dateJournal,
          undefined,
          false
        );
        if (data && data.data) {
          //console.log("data.data:", data.data);
          const formattedTable = data.data.map((journal) => {
            return {
              admin: journal.admin.lastname + " " + journal.admin.firstname,
              details: journal.text,
              date: journal.dateHeure,
            };
          });
          this.activityData = formattedTable;
          //console.log('this.users:', this.users)
        }
      }
    },
    async all() {
      const data = await apiRequest(
        "GET",
        "admin/journal?all=" + true + "&date=" + this.dateJournal,
        undefined,
        false
      );
      if (data && data.data) {
        //console.log("data.data:", data.data);
        const formattedTable = data.data.map((journal) => {
          return {
            admin: journal.admin.lastname + " " + journal.admin.firstname,
            details: journal.text,
            date: journal.dateHeure,
          };
        });
        this.activityData = formattedTable;
        //console.log('this.users:', this.users)
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5></h5>
              <div>
                <div class="form-group">
                  <label class="control-label">Choisir l'utilisateur :</label>
                  <multiselect
                    v-model="value"
                    :options="users"
                    label="name"
                    track-by="name"
                  ></multiselect>
                </div>
                <div class="mt-5">
                  <button
                    class="btn btn-primary float-right"
                    v-on:click="submitAdminAdd()"
                  >
                    Ajouter
                  </button>
                </div>
                <!--<h5>Autorisations</h5>
                <h6 class="mt-3">Trajets</h6>
                <hr />
                <div class="row">
                  <div class="col-md-2">
                    <input type="checkbox" class="mr-2" />
                    <span>En cours</span>
                  </div>
                  <div class="col-md-4">
                    <input type="checkbox" class="mr-2" />
                    <span>A venir & effectués</span>
                  </div>
                  <div class="col-md-2">
                    <input type="checkbox" class="mr-2" />
                    <span>Annulés</span>
                  </div>
                </div>
                <h6 class="mt-4">Utilisateurs</h6>
                <hr />
                <div class="row">
                  <div class="col-md-2">
                    <input type="checkbox" class="mr-2" />
                    <span>Liste</span>
                  </div>
                </div>
                <h6 class="mt-4">Porte monnaie</h6>
                <hr />
                <div class="row">
                  <div class="col-md-3">
                    <input type="checkbox" class="mr-2" />
                    <span>Virements</span>
                  </div>
                  <div class="col-md-3">
                    <input type="checkbox" class="mr-2" />
                    <span>Remboursements</span>
                  </div>
                  <div class="col-md-3">
                    <input type="checkbox" class="mr-2" />
                    <span>Suivi des gains</span>
                  </div>
                  <div class="col-md-3">
                    <input type="checkbox" class="mr-2" />
                    <span>Statistiques</span>
                  </div>
                </div>
                <h6 class="mt-4">Alertes</h6>
                <hr />
                <div class="row">
                  <div class="col-md-3">
                    <input type="checkbox" class="mr-2" />
                    <span>Trajets signalés</span>
                  </div>
                  <div class="col-md-3">
                    <input type="checkbox" class="mr-2" />
                    <span>Membres signalés</span>
                  </div>
                  <div class="col-md-3">
                    <input type="checkbox" class="mr-2" />
                    <span>Avis négatifs</span>
                  </div>
                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <form @submit.prevent="submitAdminAdd">
                <h6 class="">Ajouter des accès</h6>
                <hr />
                <div class="row">
                  <div class="col-md-3">
                    <input
                      type="checkbox"
                      value="0"
                      v-model="toutDroits"
                      @click="toutchange()"
                      class="mr-2"
                    />
                    <b style="color:red">Tout</b>
                  </div>
                  <div class="col-md-3">
                    <input
                      type="checkbox"
                      value="1"
                      v-model="trajetDroits"
                      @click="trajetchange()"
                      class="mr-2"
                    />
                    <span>Trajets</span>
                  </div>
                  <div class="col-md-3">
                    <input
                      type="checkbox"
                      value="2"
                      v-model="reserveDroits"
                      @click="reservechange()"
                      class="mr-2"
                    />
                    <span>Réservations</span>
                  </div>
                  <div class="col-md-3">
                    <input
                      type="checkbox"
                      value="3"
                      v-model="utilisateurDroits"
                      @click="utilisateurchange()"
                      class="mr-2"
                    />
                    <span>Utilisateur</span>
                  </div>
                </div>
                <h6 class="mt-4"></h6>
                <div class="row">
                  <div class="col-md-3">
                    <input
                      type="checkbox"
                      value="4"
                      v-model="donsDroits"
                      @click="donschange()"
                      class="mr-2"
                    />
                    <span>Dons</span>
                  </div>
                  <div class="col-md-3">
                    <input
                      type="checkbox"
                      value="5"
                      v-model="bonsDroits"
                      @click="bonschange()"
                      class="mr-2"
                    />
                    <span>Bons plans</span>
                  </div>
                  <div class="col-md-3">
                    <input
                      type="checkbox"
                      value="6"
                      v-model="alertesDroits"
                      @click="alerteschange()"
                      class="mr-2"
                    />
                    <span>Alertes</span>
                  </div>
                  <div class="col-md-3">
                    <input
                      type="checkbox"
                      value="7"
                      v-model="transactionsDroits"
                      @click="transactionschange()"
                      class="mr-2"
                    />
                    <span>Transactions</span>
                  </div>
                </div>
                <h6 class="mt-4"></h6>
                <div class="row">
                  <div class="col-md-3">
                    <input
                      type="checkbox"
                      value="8"
                      v-model="gainsDroits"
                      @click="gainschange()"
                      class="mr-2"
                    />
                    <span>Gains</span>
                  </div>
                  <div class="col-md-3">
                    <input
                      type="checkbox"
                      value="9"
                      v-model="virementDroits"
                      @click="virementchange()"
                      class="mr-2"
                    />
                    <span>Virements</span>
                  </div>
                  <div class="col-md-3">
                    <input
                      type="checkbox"
                      value="10"
                      v-model="adminDroits"
                      @click="adminchange()"
                      class="mr-2"
                    />
                    <span>Admin</span>
                  </div>
                  <div class="col-md-3">
                    <input
                      type="checkbox"
                      value="11"
                      v-model="messageDroits"
                      @click="messagechange()"
                      class="mr-2"
                    />
                    <span>Messages</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-body mt-3">
            <b-tabs pills justified content-class="p-3 text-muted">
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block"
                    ><i class="mdi mdi-clipboard-account mr-2"></i>Gérer les
                    administrateurs</span
                  >
                </template>
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="col-md-12">
                        <b-card
                          header-class="bg-transparent border-primary"
                          class="border border-primary"
                        >
                          <template v-slot:header>
                            <h5 class="my-0 mb-4">Nombre d'administrateur</h5>
                          </template>
                          <div class="float-right">
                            <h1 class="text-primary">{{ this.nbrAdmin }}</h1>
                          </div>
                        </b-card>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-body">
                      <div class="col-md-12">
                        <span class="" style="font-weight: bold; color: black"
                          >Liste des administrateurs</span
                        >

                        <b-tabs nav-class="nav-tabs-custom">
                          <b-tab title-link-class="p-3">
                            <div class="row mt-4">
                              <div class="col-sm-12 col-md-6">
                                <div
                                  id="tickets-table_length"
                                  class="dataTables_length"
                                >
                                  <label
                                    class="d-inline-flex align-items-center"
                                  >
                                    Afficher&nbsp;
                                    <b-form-select
                                      v-model="perPage"
                                      size="sm"
                                      :options="pageOptions"
                                    ></b-form-select
                                    >&nbsp;entrées
                                  </label>
                                </div>
                              </div>
                              <!-- Search -->
                              <div class="col-sm-12 col-md-6">
                                <div
                                  id="tickets-table_filter"
                                  class="dataTables_filter text-md-right"
                                >
                                  <label
                                    class="d-inline-flex align-items-center"
                                  >
                                    Rechercher:
                                    <b-form-input
                                      v-model="filter"
                                      type="search"
                                      class="form-control form-control-sm ml-2"
                                    ></b-form-input>
                                  </label>
                                </div>
                              </div>
                              <!-- End search -->
                            </div>

                            <div class="table-responsive">
                              <b-table
                                class="table-centered"
                                :items="adminData"
                                :fields="admin"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                                ><template v-slot:cell(etat)="row">
                                  <div
                                    class="badge font-size-12"
                                    :class="{
                                      'badge-soft-danger':
                                        `${row.value}` === 'Suspendu',
                                      'badge-soft-success':
                                        `${row.value}` === 'Actif',
                                    }"
                                  >
                                    {{ row.value }}
                                  </div>
                                </template>
                                <template v-slot:cell(invoice)>
                                  <button class="btn btn-light btn-rounded">
                                    Invoice
                                    <i class="mdi mdi-download ml-2"></i>
                                  </button>
                                </template>
                                <template v-slot:cell(action)="row">
                                  <a
                                    href="javascript:void(0);"
                                    class="text-danger"
                                    v-b-tooltip.hover
                                    @click="desactivity(row.item)"
                                    title="Désactiver"
                                  >
                                    <i class="mdi mdi-close font-size-18"></i>
                                  </a>
                                </template>
                              </b-table>
                            </div>
                            <div class="row">
                              <div class="col">
                                <div
                                  class="dataTables_paginate paging_simple_numbers float-right"
                                >
                                  <ul
                                    class="pagination pagination-rounded mb-0"
                                  >
                                    <!-- pagination -->
                                    <b-pagination
                                      v-model="currentPage"
                                      :total-rows="rows"
                                      :per-page="perPage"
                                    ></b-pagination>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </b-tab>
                        </b-tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block"
                    ><i class="mdi mdi-clipboard-account mr-2"></i>Journal des
                    activités</span
                  >
                </template>
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row d-flex justify-content-center">
                            <input
                              type="date"
                              id=""
                              class=""
                              v-model="dateJournal"
                            />
                            <button
                              class="btn btn-primary ml-2"
                              @click="search"
                            >
                              Rechercher
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="row d-flex justify-content-center">
                        <div
                          class="col-md-12 mt-3 d-flex justify-content-center"
                        >
                          <button class="btn btn-warning" @click="all">
                            Voir tout
                          </button>
                        </div>
                      </div>
                      <div class="table-responsive mt-3">
                        <b-table
                          class="table-centered"
                          :items="activityData"
                          :fields="activity"
                          responsive="sm"
                          :per-page="perPage"
                          :current-page="currentPage"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          @filtered="onFiltered"
                        >
                          <template v-slot:cell(paymentstatus)="row">
                            <div
                              class="badge font-size-12"
                              :class="{
                                'badge-soft-danger':
                                  `${row.value}` === 'Chargeback',
                                'badge-soft-success': `${row.value}` === 'Paid',
                                'badge-soft-warning':
                                  `${row.value}` === 'Unpaid',
                              }"
                            >
                              {{ row.value }}
                            </div>
                          </template>
                          <template v-slot:cell(invoice)>
                            <button class="btn btn-light btn-rounded">
                              Invoice
                              <i class="mdi mdi-download ml-2"></i>
                            </button>
                          </template>
                          <template v-slot:cell(action)>
                            <a
                              href="javascript:void(0);"
                              class="btn btn-success mr-3"
                              v-b-tooltip.hover
                              title="virement"
                            >
                              Virement
                            </a>
                          </template>
                        </b-table>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div
                            class="dataTables_paginate paging_simple_numbers float-right"
                          >
                            <ul class="pagination pagination-rounded mb-0">
                              <!-- pagination -->
                              <b-pagination
                                v-model="currentPage"
                                :total-rows="rows"
                                :per-page="perPage"
                              ></b-pagination>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showdisable"
      title="Voulez-vous vraiment suspendre cet utilisateur ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showdisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactiveUser"
          >Oui</b-button
        >
      </div>
    </b-modal>
    <b-modal
      id="modal-xl"
      size="xl"
      v-model="showAll"
      title="Extra large modal"
      title-class="font-18"
      hide-footer
    >
      <b-tabs nav-class="nav-tabs-custom">
        <b-tab title-link-class="p-3">
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Afficher&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entrées
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Rechercher:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive">
            <b-table
              class="table-centered"
              :items="activityData"
              :fields="activity"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(paymentstatus)="row">
                <div
                  class="badge font-size-12"
                  :class="{
                    'badge-soft-danger': `${row.value}` === 'Chargeback',
                    'badge-soft-success': `${row.value}` === 'Paid',
                    'badge-soft-warning': `${row.value}` === 'Unpaid',
                  }"
                >
                  {{ row.value }}
                </div>
              </template>
              <template v-slot:cell(invoice)>
                <button class="btn btn-light btn-rounded">
                  Invoice
                  <i class="mdi mdi-download ml-2"></i>
                </button>
              </template>
              <template v-slot:cell(action)>
                <a
                  href="javascript:void(0);"
                  class="btn btn-success mr-3"
                  v-b-tooltip.hover
                  title="virement"
                >
                  Virement
                </a>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
  </Layout>
</template>

<style></style>
